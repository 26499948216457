.wrapper {
    height: 10vh;
    display: flex;
    justify-content: space-between;
}

.left {
    flex: 1;
    align-items: center;
    display: flex;
    gap: 2rem;
}

.name {
    font-size: 1.3rem;
    font-weight: bold;

}

.right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.list {
    flex: 10;
}

.list>ul {
    display: flex;
    gap: 2rem;
    margin-right: 4rem;

}

.list>ul>li {
    list-style: none;
    transition: all 0.3s ease-in-out;
}

.list>ul>li:hover {
    cursor: pointer;
    color: var(--orange);
}

.nButton {
    flex: 2;
}

@media screen and (max-width: 480px) {
    .list {
      display: none;
    }
  }
  