.contactForm{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 4rem;
      /* scroll */
  padding-top: 3rem;
}


/* left side */
.awesome {
    display: flex;
    flex-direction: column;
    position: relative;
    /* gap: 20px; */
}

.awesome> :nth-child(1) {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(2) {
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(3) {
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
}

.cButton {
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}

.right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.right>form
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    
}

.right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;   
}

textarea{
    height: 4rem!important;
}

.cBlur1{
    top: 1rem !important;
    left: 8rem !important;
}

.sBlur1 {
    top: 13rem !important;
    left: -18rem !important;
    background-color: #ABF1FF94 !important;
}

@media screen and (max-width: 480px) {
    .contactForm{
        padding: 0;
        flex-direction: column;
        gap: 5rem;
        height: 40rem;
    }
    .right .user{
        width: 16rem;
    }
}   